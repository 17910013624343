import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from '../components/layout';
import Seo from "../components/seo";
import { sliceIntoChunks } from '../utils/utils';
import HeaderPage from '../utils/HeaderPage';

const getdata = graphql`
{
    wpgraphql {
      AUTO_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, categoryName: "Autonomous Vehicles"}, first: 50) {
        nodes {
          slug
          title
          id
        }
      }
      THREED_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, categoryName: "3-D Printing"}, first: 50) {
        nodes {
          slug
          title
          id
        }
      }
      AI_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, categoryName: "Artificial Intelligence"}, first: 50) {
        nodes {
          slug
          title
          id
        }
      }
      APPLE_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, tag: "Apple"}, first: 20) {
          nodes {
            slug
            title
            id
          }
      }
      GOOGLE_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, tag: "Google/Alphabet"}, first: 10) {
        nodes {
          slug
          title
          id
        }
      }
      META_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, tag: "Meta/Facebook"}, first: 15) {
        nodes {
          slug
          title
          id
        }
      }
      SAMSUNG_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, tag: "Samsung"}, first: 15) {
        nodes {
          slug
          title
          id
        }
      }
      MICROSOFT_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, tag: "Microsoft"}, first: 10) {
        nodes {
          slug
          title
          id
        }
      }
      ELEC_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, categoryName: "Electronics"}, first: 50) {
        nodes {
          slug
          title
          id
        }
      }
      COMM_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, categoryName: "Communications"}, first: 50) {
        nodes {
          slug
          title
          id
        }
      }
      FINTECH_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, categoryName: "Blockchain and Fintech"}, first: 50) {
        nodes {
          slug
          title
          id
        }
      }
      MEDICAL_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, categoryName: "Medical Device"}, first: 50) {
        nodes {
          slug
          title
          id
        }
      }
      METAVERSE_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, categoryName: "Metaverse"}, first: 10) {
        nodes {
          slug
          title
          id
        }
      }
      NANO_PATENTS: posts(where: {orderby: {field: DATE, order: DESC}, categoryName: "Nanotechnology"}, first: 10) {
        nodes {
          slug
          title
          id
        }
      }
    }
}
`
const Sitemap = () => {
  const data = useStaticQuery(getdata);
  const metaPatents = data.wpgraphql.META_PATENTS.nodes;
  const googlePatents = data.wpgraphql.GOOGLE_PATENTS.nodes;
  const applePatents = data.wpgraphql.APPLE_PATENTS.nodes;
  const samsungPatents = data.wpgraphql.SAMSUNG_PATENTS.nodes;
  const microsoftPatents = data.wpgraphql.MICROSOFT_PATENTS.nodes;
  const softwarePatents = metaPatents.concat(googlePatents, applePatents, samsungPatents, microsoftPatents);
  const patents = sliceIntoChunks(softwarePatents, Math.round(softwarePatents.length/4));
  // Electronics
  const elecPatents = sliceIntoChunks(data.wpgraphql.ELEC_PATENTS.nodes, Math.round(data.wpgraphql.ELEC_PATENTS.nodes.length/4));
  const commPatents = sliceIntoChunks(data.wpgraphql.COMM_PATENTS.nodes, Math.round(data.wpgraphql.COMM_PATENTS.nodes.length/4));
  const threeDPatents = sliceIntoChunks(data.wpgraphql.THREED_PATENTS.nodes, Math.round(data.wpgraphql.THREED_PATENTS.nodes.length/4));
  const aiPatents = sliceIntoChunks(data.wpgraphql.AI_PATENTS.nodes, Math.round(data.wpgraphql.AI_PATENTS.nodes.length/4));
  const autoPatents = sliceIntoChunks(data.wpgraphql.AUTO_PATENTS.nodes, Math.round(data.wpgraphql.AUTO_PATENTS.nodes.length/4));
  const fintechPatents = sliceIntoChunks(data.wpgraphql.FINTECH_PATENTS.nodes, Math.round(data.wpgraphql.FINTECH_PATENTS.nodes.length/4));
  const medicalPatents = sliceIntoChunks(data.wpgraphql.MEDICAL_PATENTS.nodes, Math.round(data.wpgraphql.MEDICAL_PATENTS.nodes.length/4));
  const metaversePatents = sliceIntoChunks(data.wpgraphql.METAVERSE_PATENTS.nodes, Math.round(data.wpgraphql.METAVERSE_PATENTS.nodes.length/4));
  const nanoPatents = sliceIntoChunks(data.wpgraphql.NANO_PATENTS.nodes, Math.round(data.wpgraphql.NANO_PATENTS.nodes.length/4));
  const canonicalUrl = `/sitemap`;
  const seo = {};

  return (
    <Layout>
      <Seo title={ `Sitemap ` } canonical={ canonicalUrl } seo={seo} />
      <HeaderPage headerTxt={'Sitemap'}></HeaderPage>
      {/* <section className=""> */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 Software Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    patents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>

        {/* 3D printing  */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 3-D Printing Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    threeDPatents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>

        {/* AI */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 Artificial Intelligence (AI) Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    aiPatents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>

        {/* Auto  */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 Autonomous Vehicles Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    autoPatents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>
{/* Fintech */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 Blockchain and Fintech Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    fintechPatents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>


        {/* Electronic */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 Electronic Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    elecPatents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>

        {/* Communication */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 Communication Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    commPatents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>

        {/* Medical device */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 Medical Device Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    medicalPatents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>
        {/* Metaverse */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 Metaverse Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    metaversePatents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>
        {/* Nano */}
        <div className="">
          <div className="container mainSpacing2">
            <div className="row">
                <h2 className="mb-2 h3 mb-4">
                  50 Nanotechnology Patents
                </h2>
                <div className="col-md-12">
                    <div className="row">
                {
                    nanoPatents.map((nodes, index) => (
                      <div className="col-md-3">
                        { nodes.map((node, se) => (
                          <div className="py-3 border-bottom">
                            <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                {node.title}
                              </Link>
                          </div>
                        ))}
                      </div>
                    ))
                }
                    </div>
                </div>
            </div>
          </div>
        </div>
      {/* </section> */}
    </Layout>
  )
}

export default Sitemap;
